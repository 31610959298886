import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from './environment.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PostSpeiService {
  private _env: string = this.env._env;
  private url = null;

  constructor(private http: HttpClient, private env: EnvironmentService) {}

  public postContalinkSpei(postSlack: PostSpei): Observable<any> {

    let headers: HttpHeaders = new HttpHeaders({
      'Content-Type':  'application/json',
      'X-Api-Key': environment[this._env]['back_end_api_token']
    })
    this.url = `${environment[this._env]['back_end_url']}/postspei`;
    
    return this.http.post<any>(this.url, 
    postSlack,
    {
      headers: headers
    })
  }
}
export interface PostSpei {
    name: string,
    email: string,
    phone: string,
    rfc: string,
    razon_social:string,
    rfc_facturacion: string,
    razon_social_facturacion: string,
    numero_usuarios: string,
    plan_id: string,
    total:string,
    discount:string,
    userpass:string,
    regimenName:string,
    regimen:string,
    postal_code:string,
    fuente:string,
    fuenteName:string,
    precio_deal:string,
    descuento_deal:string,
    utm_medium:string,
    utm_source:string,
    utm_campaign:string,
    first_utm:string,
    first_visit_date:string,
    referred:string,
    referred_name:string;
    referred_code:string;
    halfoff:number;
    business_partner_id:string;
}