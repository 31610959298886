export const REGIMEN_LIST = [
  { "id": "601", "value": "General de Ley Personas Morales" },
  { "id": "603", "value": "Personas Morales con Fines no Lucrativos" },
  { "id": "605", "value": "Sueldos y Salarios e Ingresos Asimilados a Salarios" },
  { "id": "606", "value": "Arrendamiento" },
  { "id": "608", "value": "Demás ingresos" },
  { "id": "609", "value": "Consolidación" },
  { "id": "610", "value": "Residentes en el Extranjero sin Establecimiento Permanente en México" },
  { "id": "611", "value": "Ingresos por Dividendos (socios y accionistas)" },
  { "id": "612", "value": "Personas Físicas con Actividades Empresariales y Profesionales" },
  { "id": "614", "value": "Ingresos por intereses" },
  { "id": "616", "value": "Sin obligaciones fiscales" },
  { "id": "620", "value": "Sociedades Cooperativas de Producción que optan por diferir sus ingresos" },
  { "id": "621", "value": "Incorporación Fiscal" },
  { "id": "622", "value": "Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras" },
  { "id": "623", "value": "Opcional para Grupos de Sociedades" },
  { "id": "624", "value": "Coordinados" },
  { "id": "628", "value": "Hidrocarburos" },
  { "id": "607", "value": "Régimen de Enajenación o Adquisición de Bienes" },
  { "id": "629", "value": "De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales" },
  { "id": "630", "value": "Enajenación de acciones en bolsa de valores" },
  { "id": "615", "value": "Régimen de los ingresos por obtención de premios" },
  { "id": "625", "value": "Régimen de Actividades Empresariales con ingreso a través de las plataformas tecnológicas" },
  { "id": "626", "value": "Régimen Simplificado de Confianza" }
];

export const FUENTE_LIST = [
  { "id": "439", "value": "Anuncio en Facebook" },
  { "id": "350", "value": "Búsqueda en Google" },
  { "id": "355", "value": "Instituto mexicano de contadores" },
  { "id": "440", "value": "Información Factura" },
  { "id": "441", "value": "Instagram" },
  { "id": "442", "value": "Correo" },
  { "id": "354", "value": "Podcast" },
  { "id": "351", "value": "Recomendación" },
  { "id": "358", "value": "Tik Tok" },
  { "id": "363", "value": "YouTube" },
  { "id": "481", "value": "TV" },
  { "id": "482", "value": "Panorámico" },
  { "id": "483", "value": "Periódico" },
  { "id": "489", "value": "Grupo Facebook" },
  { "id": "490", "value": "Grupo Whatsapp" },
  { "id": "503", "value": "Contenido Partners" }
];

export const PROFESIONAL_LIST = [
  "RFCs ilimitados",
  "Descarga masiva de CFDIs",
  "Soporte",
  "Capacitación",
  "Actualizaciones",
  "Servidores",
  "Descarga automática de CFDIs desde el SAT",
  "Contabilidad e Impuestos",
  "Tesorería",
  "Auditoría",
  "Emisión de facturas y complementos (adquisición adicional de paquete de timbres)"
];

export const PREMIUM_LIST = [
  "RFCs ilimitados",
  "Descarga masiva de CFDIs",
  "Soporte",
  "Capacitación",
  "Actualizaciones",
  "Servidores",
  "Todos los beneficios de Plan Pro ",
  "Cálculo y timbrado de Nómina",
  "Facturación recurrente y cobranza",
  "Timbres ilimitados para facturas, complementos, notas de crédito y nómina",
  `Tablero de documentos de todos los clientes, descarga automática de: 
      <ul>
        <li>Constancia de RFC</li>
        <li>Opinión de cumplimiento</li>
        <li>Opinión de IMSS e INFONAVIT</li>
        <li>Comunicados del Buzón Tributario SAT</li>
        <li>Declaraciones</li>
      </ul>`,
  "White Label (Marca blanca, requiere un mínimo de usuarios)"
];

export const DISCOUNT_TABLE: { [key: number]: number } = {
  1: 0,
  2: 25,
  3: 30,
  4: 35,
  5: 40,
  6: 45,
  7: 50,
  8: 50,
  9: 50,
  10: 50,
  11: 52,
  12: 54,
  13: 56,
  14: 58,
  15: 60,
  16: 61,
  17: 62,
  18: 63,
  19: 64,
  20: 65,
  21: 66,
  22: 67,
  23: 68,
  24: 69,
  25: 70,
};