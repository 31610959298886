import { Injectable, EventEmitter} from '@angular/core';
import Swal from 'sweetalert2';
import { nanoid, customAlphabet } from 'nanoid';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { EnvironmentService } from './environment.service';


@Injectable({
  providedIn: 'root'
})
export class SweetAlertService {
  receivedEmail: string;
  codeConfirmation: string;
  dateConfirmation: string;
  codeIsCorrect: boolean;
  private _env: string = this.env._env;

  modalAccepted = new EventEmitter<any>();


  constructor(private http: HttpClient, private env: EnvironmentService) {

  }

  async codeSweetAlert(email: string): Promise<boolean> {
    this.receivedEmail = email;
    await this.sendCode();
    return Swal.fire({
      width: 600,
      title: "Usuario existente",
      text: "",
      html: `<label class="sweet-alert-text">El correo registrado ya existe como usuario de Contalink. Hemos enviado un código de verificación a tu CORREO, favor de introducirlo para poder avanzar.</label>
          <div class="row">
            <div class="col-lg-12 container-fluid" style="justify-content: center; display: flex;">
              <div class="col-lg-1"><input id="letter1" pattern="[a-zA-Z0-9]+" style="padding: 25px 0; width: 1em; font-size: 3.5em; text-align: center;" maxlength="1" class="form-control"></div>
              <div class="col-lg-1"><input id="letter2" pattern="[a-zA-Z0-9]+" style="padding: 25px 0; width: 1em; font-size: 3.5em; text-align: center;" maxlength="1" class="form-control"></div>
              <div class="col-lg-1"><input id="letter3" pattern="[a-zA-Z0-9]+" style="padding: 25px 0; width: 1em; font-size: 3.5em; text-align: center;" maxlength="1" class="form-control"></div>
              <div class="col-lg-1"><input id="letter4" pattern="[a-zA-Z0-9]+" style="padding: 25px 0; width: 1em; font-size: 3.5em; text-align: center;" maxlength="1" class="form-control"></div>
              <div class="col-lg-1"><input id="letter5" pattern="[a-zA-Z0-9]+" style="padding: 25px 0; width: 1em; font-size: 3.5em; text-align: center;" maxlength="1" class="form-control"></div>
              <div class="col-lg-1"><input id="letter6" pattern="[a-zA-Z0-9]+" style="padding: 25px 0; width: 1em; font-size: 3.5em; text-align: center;" maxlength="1" class="form-control"></div>
            </div>
        </div>`,
      didOpen: () => {
        const inputs = Array.from(document.querySelectorAll('.swal2-container .form-control')) as HTMLInputElement[];

        inputs.forEach((input, index) => {
          input.addEventListener('keyup', (e: KeyboardEvent) => {
            if (e.key !== 'Backspace' && e.key !== 'Delete') {
              if (input.value.length === 1 && index < inputs.length - 1) {
                (inputs[index + 1] as HTMLInputElement).focus();
              }
            }
          });

          input.addEventListener('keydown', (e: KeyboardEvent) => {
            if (e.key === 'Backspace' || e.key === 'Delete') {
              if (input.value.length === 0 && index > 0) {
                (inputs[index - 1] as HTMLInputElement).focus();
              }
            }
          });
        });
      },
      inputAttributes: {
        autocapitalize: "off"
      },
      showCancelButton: true,
      cancelButtonText: "Usar otro correo",
      confirmButtonText: "Confirmar código",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        await this.getCodeInfo();

        const letter1 = (document.getElementById('letter1') as HTMLInputElement).value;
        const letter2 = (document.getElementById('letter2') as HTMLInputElement).value;
        const letter3 = (document.getElementById('letter3') as HTMLInputElement).value;
        const letter4 = (document.getElementById('letter4') as HTMLInputElement).value;
        const letter5 = (document.getElementById('letter5') as HTMLInputElement).value;
        const letter6 = (document.getElementById('letter6') as HTMLInputElement).value;

        const code: string = letter1 + letter2 + letter3 + letter4 + letter5 + letter6;
        console.log('ESTE ES EL CODIGO', code);
        try {
          if (!code) {
            Swal.showValidationMessage('Por favor ingresa un código');
            return false;
          }
          if(!this.validateCode(code)) {
            Swal.showValidationMessage('El código es incorrecto');
            return false;
          }
          if(!this.validateTime()){
            Swal.showValidationMessage('El código ha expirado');
            return false;
          }

          return true
        }
        catch (error) {
          Swal.showValidationMessage(`Request failed: ${error}`);
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      console.log('result del segundo ', result);

      if (result.isConfirmed) {
        console.log('RESULT', result);
        Swal.fire({
          icon: "success",
          title: "Código correcto",
          showConfirmButton: false,
          timer: 1000
        });

        return true;
      } else {
        return false;
      }
    });
  }

  generateConfirmationCode(): string {
    const confirmationCode = nanoid(6);
    return confirmationCode.toString();
  }

  sendConfirmationEmail(code): void {
  }

  async getCodeInfo() {
    const response: any = await this.verifyConfirmationCode();
    const responseJson = JSON.parse(response);

    this.codeConfirmation = responseJson['confirmationcode'];
    this.dateConfirmation = responseJson['code_generated_at'];
  }

  validateCode(code: string): boolean{
    return code == this.codeConfirmation ? true : false; 
  }

  validateTime(): boolean {
    const receivedDate = new Date(this.dateConfirmation);
    const currentDate = new Date();

    const diffInMs = currentDate.getTime() - receivedDate.getTime();
    const diffInMinutes = diffInMs / (1000 * 60);

    console.log('diferencia en minutos',diffInMinutes);

    return diffInMinutes <= 15 ? true : false;
  }

  async sendCode() {
    const code: string = this.generateConfirmationCode();
    await this.saveConfirmationCode(code);
  }

  async saveConfirmationCode(userCode: string) {
    let url: string = this.assignEnvironmentUrl();

    const headers: HttpHeaders = new HttpHeaders().set('X-Api-Key', environment.api_token_backend);
    const params = new HttpParams()
      .set("userCode", userCode)
      .set("email", this.receivedEmail)
      .set("type", "insert_code");

    return this.http.post(url, null, {
      headers: headers,
      params: params
    }).toPromise();
  }

  verifyConfirmationCode(): any {
    let url: string = this.assignEnvironmentUrl();
    const headers: HttpHeaders = new HttpHeaders().set('X-Api-Key', environment.api_token_backend);

    const params = new HttpParams()
      .set('email', this.receivedEmail)
      .set('type', 'select_code');

    console.log(url);

    return this.http.post(url, null, {
      headers: headers,
      params: params
    }).toPromise();
  }

  assignEnvironmentUrl(): string {
    let url: string = '';
    url = `${environment[this._env]['back_end_url']}/update_user_code`;

    return url;
  }
}
