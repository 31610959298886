import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { environment } from '../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { CookiesService } from './services/cookies.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'contalink-com';

  constructor(
    private route: ActivatedRoute,
    private cookies: CookiesService
  ) {}
  
  public ngOnInit() {

    $(document).ready(function() {

      $('.func-item').click(function() {
        var section = $(this).attr("func");
        setClass($(this));
        changeSection(section);
      });

      let setClass = function(that) {
        $(".func-item").removeClass("func-active");
        that.addClass("func-active")
      }

      let changeSection = function(section) {
        $(".func-section").removeClass("visible");
        $(".func-section").addClass("hidden");
        $('#' + section).addClass('visible');
        $('#' + section).removeClass('hidden');
      }

      $('.thumb-video').click(function(event) {
        event.preventDefault();
        var theModal = $(this).data("target"),
          videoSRC = $(this).attr("data-theVideo"),
          videoSRCauto = videoSRC + "?autoplay=1";
        $(theModal + ' iframe').attr('src', videoSRCauto);
        $(theModal + ' button.close').click(function() {
          $(theModal + ' iframe').attr('src', videoSRC);
        });
      });


    });

    this.getUtmsFromUrl()
  }

  getUtmsFromUrl() {
    this.route.queryParams.subscribe((params) => {
      const { utm_medium, utm_source, utm_campaign, utm_term, utm_content, ad_set, first_utm, first_visit_date, fbclid, gclid, ttclid } = params
      if (utm_medium)
        this.cookies.createCookie('utm_medium', utm_medium ? utm_medium : 'Desconocido', 15)
      if (utm_source)
        this.cookies.createCookie('utm_source', utm_source ? utm_source : 'Desconocido', 15)
      if (utm_campaign)
        this.cookies.createCookie('utm_campaign', utm_campaign ? utm_campaign : 'Desconocido', 15)
      if (utm_term)
        this.cookies.createCookie('utm_term', utm_term ? utm_term : 'Desconocido', 15)
      if (utm_content)
        this.cookies.createCookie('utm_content', utm_content ? utm_content : 'Desconocido', 15)
      if (ad_set)
        this.cookies.createCookie('ad_set', ad_set ? ad_set : 'Desconocido', 15)
      if (fbclid)
        this.cookies.createCookie('fbclid', fbclid ? fbclid : 'Desconocido', 15)
      if (gclid)
        this.cookies.createCookie('gclid', gclid ? gclid : 'Desconocido', 15)
      if (ttclid)
        this.cookies.createCookie('ttclid', ttclid ? ttclid : 'Desconocido', 15)
      if (first_utm)
        this.cookies.createCookie('first_utm', first_utm ? first_utm : 'Desconocido', 15)
      if (first_visit_date)
        this.cookies.createCookie('first_visit_date', first_visit_date ? first_visit_date : 'Desconocido', 15)
    })
  }

}
