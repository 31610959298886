import { Injectable } from "@angular/core";
import { GetPlansService } from '../../services/get-plans.service';
import { CheckCompanyService } from '../../services/check-company.service';
import { MixPanelService } from '../../services/mix-panel.service';
import { PostLogTransactionService } from '../../services/post-log-transaction.service';
import { GetLiteInfoService } from '../../services/get-lite-info.service';
import { GetReferredInfoService } from '../../services/get-referred-info.service';

@Injectable({
  providedIn: 'root'
})

export class UserService {

  private url_path: string = window.location.href;

  constructor(
    private GetPlans: GetPlansService,
    private CheckCompany: CheckCompanyService,
    private mixpanelService: MixPanelService,
    private PostLogTransaction: PostLogTransactionService,
    public GetLiteInfo: GetLiteInfoService,
    public GetReferredInfo: GetReferredInfoService,
  ) { }

  public async allPlans(): Promise<any> {
    return await this.GetPlans.getContalinkPlans({}).toPromise();
  }

  public async checkRFC(rfc: string, email: string, phone: string): Promise<any> {
    return await this.CheckCompany.checkContalinkCompany({
      rfc, email, phone
    }).toPromise();
  }

  public fillDataValues(secondFormControl: any, isQa: boolean, usersCount, selectedPlan, selectedRecurrence, totalSale, totalDiscount, selectedRegimenName,
    selectedRegimen, selectedFuente, selectedFuenteName, precio_deal, descuento_deal, utm_medium, utm_source, utm_campaign, fbclid, gclid, ttclid, utm_id, version, utm_term, utm_content, ad_set,
    first_utm, first_visit_date, lite_code, referred_id, referredEmail, half_off_discount, volumeDiscount, annualDiscount,
    volume_discount_amount, annual_discount_amount, acceptedPromotionalEmail): any {
    

    return {
      "name": secondFormControl.name.value,
      "email": secondFormControl.email.value,
      "userpass": secondFormControl.userpass.value,
      "phone": secondFormControl.phone.value,
      "rfc": secondFormControl.rfc.value,
      "razon_social": secondFormControl.company.value,
      "rfc_facturacion": secondFormControl.rfc_invoicing.value,
      "razon_social_facturacion": secondFormControl.company_invoicing.value,
      "numero_usuarios": usersCount.toString(),
      "plan_id": selectedPlan,
      "recurrence": selectedRecurrence,
      "total": totalSale,
      "discount": totalDiscount,
      "rfc_exists": false,
      "qa": isQa,
      "rfc_generico": false,
      "business_partner_id": null,
      "regimenName": selectedRegimenName,
      "regimen": selectedRegimen,
      "fuente": selectedFuente,
      "fuenteName": selectedFuenteName,
      "postal_code": secondFormControl.postal_code.value,
      "precio_deal": precio_deal,
      "descuento_deal": descuento_deal,
      "utm_medium": utm_medium,
      "utm_source": utm_source,
      "utm_campaign": utm_campaign,
      "fbclid": fbclid,
      "gclid": gclid,
      "ttclid": ttclid,
      "utm_id": utm_id,
      "version": version,
      "utm_term": utm_term,
      "utm_content": utm_content,
      "ad_set": ad_set,
      "first_utm": first_utm,
      "first_visit_date": first_visit_date,
      "lite_code": lite_code,
      "referred": referred_id == null ? 'false' : 'true',
      "referred_code": referred_id,
      "referred_name": referredEmail,
      "halfoff": half_off_discount,
      "new_rfc_lite": null,
      "volume_discount": volumeDiscount,
      "annual_discount": annualDiscount,
      "volume_discount_amount": volume_discount_amount,
      "annual_discount_amount": annual_discount_amount,
      "acceptedPromotionalEmail": acceptedPromotionalEmail
    }
  }

  addMixPanelEvent(action, params): void {
    this.mixpanelService.track(
      action,
      params,
      this.url_path
    );
  }

  async addLogTransaction(action, landing_record_id, hashSession): Promise<void> {
    let browser_t = new Date();
    let post_slack: any = await this.PostLogTransaction.postContalinkLogTransaction(
      {
        action_key: action,
        browsertime: browser_t.toISOString(),
        session_id: hashSession,
        record_landing_id: landing_record_id
      }
    ).toPromise()
  }


  validateAllFields(secondFormGroup: any, selectedRegimen: any, acceptedTyC: boolean): any {
    let message: string = "";

    if (secondFormGroup.get('name').invalid) {
      message = message + "- Nombre\n"
    }
    if (secondFormGroup.get('phone').invalid) {
      message = message + "- Télefono\n"
    }
    if (secondFormGroup.get('email').invalid) {
      message = message + "- Email\n"
    }
    if (secondFormGroup.get('userpass').invalid) {
      message = message + "- Contraseña\n"
    }
    if (secondFormGroup.get('company').invalid) {
      message = message + "- Razón Social\n"
    }
    if (secondFormGroup.get('rfc').invalid) {
      message = message + "- RFC\n"
    }
    if (secondFormGroup.get('postal_code').invalid) {
      message = message + "- Código Postal\n"
    }
    if (selectedRegimen == null) {
      message = message + "- Régimen Fiscal\n"
    }
    if (!acceptedTyC) {
      message += "- Acepta los términos y condiciones\n"
    }
    if (message != "") {
      return {
        "status": false,
        "message": "Favor de validar la siguiente información obligatoria:\n" + message
      }
    } else {
      return { "status": true }
    }
  }

  makeHashSession(): string {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < 30; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  async getLiteInfo(lite_code: any): Promise<any> {
    return await this.GetLiteInfo.getContalinkLiteInfo({
      lite_code: lite_code
    }).toPromise();
  }

  async getReferredInfo(referred_id): Promise<any> {
    return await this.GetReferredInfo.getContalinkReferredInfo({
      referred_id: referred_id
    }).toPromise();
  }

}